@import url("./lib/icons.less");
@import url("./lib/settings.less");
@import url("./lib/functions.less");
@import url("./lib/animations.less");
@import url("./classes/classes.less");
@import url("./nav.less");

#login-wrapper{

	position:absolute;
	width:350px;
	height:auto;
	top:35%;
	left:50%;

	.transform2d(@translateX: -50%, @translateY:-50%);
	
	.displayFlex(column);
	.justifyContent(center);
	.alignContent(flex-start);
	.flexWrap(nowrap);


	#response-login{
		
		display:block;
		width:100%;

		h4{
			margin-top:0;
			margin-bottom: (@m_headlines * 1);
			line-height: 140%;
			color: @c_3rd;
			font-size: ( @f_mainSize * 1.5);
			text-transform: none;

			.f_1stFontBlack;

			&.userNotFound{
				text-transform:none;
			}
		}
	}

	#login-form{

		display:inline-block;
		width:100%;
		height:auto;

		input{

			display:block;
			padding: @p_main ;
			height:auto;
			margin:0;

			.standardAnimation(all);

			&.input-field{

				width: 100% - @p_main / 2 ;
				font-size: ( @f_mainSize * 1.2 );
				color: @c_3rd;
				border: 1px solid @c_4th;
				border-radius: 0 0 5px 5px;
				background-color:@c_6th;
				position:relative;
				z-index:1;

				&:focus{

					outline:none!important;
					border-color: @c_2nd;
					//.shadow3;
				}
			}

			&.input-button{

				background-color:@c_3rd;
				color:@c_6th;
				border:none;
				margin-top: @p_main;
				font-size: ( @f_mainSize * 1.2);
				width: 100% + ( @p_main / 8 );
				cursor:pointer;
				&.big{.transform2d(@scale: 1);}				
				&.small{.transform2d(@scale: 0);}
				&:hover{
					background-color:@c_2nd;
				}
			}
			&:first-child{
				margin-top:( @p_main / 2 );
				z-index:2;

				&:focus{
					margin-bottom:0;
				}
			}
			&#login-name{
				&.invalid{
					border-radius: 5px ;
				}
				&.valid{
					border-radius: 5px 5px 0 0;
				}
			}
			&#login-pwd{
				&.invalid{
					margin-top:-64px;
					border-color:transparent;
				}
				&.valid{
					margin-top: -1px;
				}
			}
		}
	}
}