// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------

// ####################################################
// #      KLASSEN STYLES                              #
// ####################################################

// Text Formate, Headlines & Links

.main-wrapper{

	display:block;
	padding: @p_main;
	margin:0;
}

.fullscreen{

	display:block;
	width:100%;
	height:100%;
	top:0;
	left:0;
	right:0;
	bottom:0;
	margin:0;
	padding:0;
}

.no-scrollbar{
	&::-webkit-scrollbar { display:none; }             /* 1 */
	&::-webkit-scrollbar-button { display:none;       /* 2 */
		&::-webkit-scrollbar-track {display:none; }       /* 3 */
		&::-webkit-scrollbar-track-piece { display:none; } /* 4 */
		&::-webkit-scrollbar-thumb { display:none; }       /* 5 */
		&::-webkit-scrollbar-corner { display:none; }      /* 6 */
		&::-webkit-resizer { display:none; }               /* 7 */
	}
}

.text{
	color: @c_1st;
	font-size: @f_mainSize;
	line-height: @f_mainLineHeight;

	//Responsive
	& when (@r_status = true) {
		@media screen and (max-width: 1024px) {
			font-size: @r_fontSizeText;
		}
	}
}

.p{
	.text;
	margin-bottom: (@m_main * 1.4);
	color:@c_4th;
	.f_1stFontLight;
}

.link{

	&, &:before{
		.standardAnimation(all);
		color: @c_1st;
	}


	&:hover, &:hover:before, &.active, &.active-trail, &.active:before, &.active-trail:before{
		.standardAnimation(all);
		color: @c_2nd;
	}
}




.strong{
	.f_1stFontBold;
}

.bold{
	.f_1stFontBold;
}

.semiBold{
	.f_1stFontBold;
}

.normal{
	.f_1stFontLight;
}

.italic{
	font-style: italic;
}

.em{
	font-style: italic;
}

.h1{
	margin-bottom: (@m_headlines * 1.6);
	line-height: 120%;
	color: @c_1st;
	font-size: 48px;
	position: relative;
	text-transform: none;
	margin-top:0;
	.f_2ndFontThin;
};

.h2{
	margin-bottom: (@m_headlines * 1);
	line-height: 130%;
	color: @c_1st;
	font-size: 32px;
	text-transform: uppercase;
	.f_2ndFont;
}

.h3{
	margin-bottom: (@m_headlines * 1.4);
	line-height: 130%;
	color: @c_1st;
	font-size: 24px;
	text-transform: uppercase;
	.f_2ndFontBold;
}

.h4{
	margin-bottom: (@m_headlines * 1);
	margin-top:0;
	line-height: 140%;
	color: @c_1st;
	font-size: 17px;
	text-transform: uppercase;
	.f_1stFontBold;
}

.h5{
	margin-bottom: (@m_headlines * 1);
	line-height: 130%;
	color: @c_2nd;
	font-size: 14px;
	.f_2ndFont;
}

.h6{
	margin-bottom: (@m_headlines / 1.4);
	line-height: 130%;
	color: @c_2nd;
	font-size: 11px;
	.f_2ndFont;
}

.hr{
	height: 0;
	display: block;
	margin-top: @p_main;
	margin-bottom: @p_main * 2;
	border: 0;
	border-bottom: 1px solid @c_4th;
}

// MARKER TEXT
.selection{
	background-color: @c_2nd;
	color: @c_5th;
}

// LISTEN
.listNormal{
  list-style-type: none;
  margin-bottom: (@p_main * 2);
  margin-top: 10px;
  
  li{
    padding-left: 14px;
    line-height: 140%;
	  margin: 0;
    margin-bottom: 6px;
	  font-size: @f_mainSize;
	  position: relative;
    
    p, h1, h2, h3, h4, h5{
    	margin-bottom: 0;
    }
    
    &:before {
	    content: "•";
	    color: @c_2nd;
	    font-size: 10px;
	    left: 0;
	    position: absolute;
	    vertical-align: middle;
	}
  }
}

.listNumber{
  list-style-type: decimal;
  margin-left: @p_block;
  margin-bottom: (@p_block);
  margin-top: -6px;
  
  li{
    padding-left: 10px;
	  .f_1stFont;
    font-size: @f_mainSize;
    line-height: 160%;
    margin-bottom: 6px;
  }
}

.resetList{
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  
  li{
    padding-left: 0;
	  .f_1stFont;
    font-size: @f_mainSize;
    line-height: 160%;
    margin-bottom: 0;
     &:before{
     	content: " ";
     }
  }
  
}

// Zuweisung Klassen zu Objekten

h1{
	.h1;
}

p{
	.p;
}


//Editor Styles

.highlighted{
	color: @c_1st;
	padding: (@p_main / 6) (@p_main / 4);
	.f_1stFontBold;
	font-size: 12px;
	text-transform: uppercase;

	.gradient4;
	
	a, a:before, a:after{
		color: @c_2nd !important;
	}
}


//Gradients
.gradient1(@_element : background-image){
	//grün
	@{_element}: -moz-linear-gradient( 0deg, rgb(79,132,0) 0%, rgb(143,186,34) 100%);
	@{_element}: -webkit-linear-gradient( 0deg, rgb(79,132,0) 0%, rgb(143,186,34) 100%);
	@{_element}: -ms-linear-gradient( 0deg, rgb(79,132,0) 0%, rgb(143,186,34) 100%);
	@{_element}: linear-gradient( 0deg, rgb(79,132,0) 0%, rgb(143,186,34) 100%);
}

.gradient2(@_element : background-image){
	//schwarz
	@{_element}: -moz-linear-gradient( 0deg, rgb(23,23,19) 0%, rgb(60,61,50) 100%);
	@{_element}: -webkit-linear-gradient( 0deg, rgb(23,23,19) 0%, rgb(60,61,50) 100%);
	@{_element}: -ms-linear-gradient( 0deg, rgb(23,23,19) 0%, rgb(60,61,50) 100%);
	@{_element}:linear-gradient( 0deg, rgb(23,23,19) 0%, rgb(60,61,50) 100%);
}

.gradient3(@_element : background-image) {
	//grauer Verlauf Diagonal
	@{_element}: -moz-linear-gradient( 60deg, rgb(145,144,139) 16%, rgb(209,210,206) 100%);
	@{_element}: -webkit-linear-gradient( 60deg, rgb(145,144,139) 16%, rgb(209,210,206) 100%);
	@{_element}: -ms-linear-gradient( 60deg, rgb(145,144,139) 16%, rgb(209,210,206) 100%);
	@{_element}: linear-gradient( 60deg, rgb(145,144,139) 16%, rgb(209,210,206) 100%);


}

.gradient4(@_element : background-image){
	//Ocker Verlauf Top Bottom
	@{_element}: -moz-linear-gradient( 135deg, rgb(255,255,255) 0%, rgba( 220, 220, 200, 1 ) 100%);
	@{_element}: -webkit-linear-gradient( 135deg, rgb(255,255,255) 0%, rgba( 220, 220, 200, 1 ) 100%);
	@{_element}: -ms-linear-gradient( 135deg, rgb(255,255,255) 0%, rgba( 220, 220, 200, 1 ) 100%);
	@{_element}: linear-gradient( 135deg, rgb(255,255,255) 0%, rgba( 220, 220, 220, 1 ) 100%);


}

.gradient5(@_element : background-image){
	//Ocker Verlauf Top Bottom
		@{_element}: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba( 255, 255, 255, 0.75 ) 55%, rgba( 255, 255, 255, 0.95 ) 100%);
		@{_element}: -webkit-linear-gradient( 180deg, rgba(255,255,255,0) 0%, rgba( 255, 255, 255, 0.75 ) 55%, rgba( 255, 255, 255, 0.95 ) 100%);
		@{_element}: -ms-linear-gradient( 180deg, rgba(255,255,255,0) 0%, rgba( 255, 255, 255, 0.75 ) 55%, rgba( 255, 255, 255, 0.95 ) 100%);
		@{_element}: linear-gradient( 180deg, rgba(255,255,255,0) 0%, rgba( 255, 255, 255, 0.75 ) 55%, rgba( 255, 255, 255, 0.95 ) 100%);
}

.gradient6(@_element : background-image) {
		@{_element}: -moz-linear-gradient(0deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.36) 47%, rgba(0,0,0,0) 67%);
		@{_element}: -webkit-linear-gradient( 0deg,rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.36) 47%, rgba(0,0,0,0) 67%);
		@{_element}: -ms-linear-gradient( 0deg,  rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.36) 47%, rgba(0,0,0,0) 67%);
		@{_element}: linear-gradient( 0deg,rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.36) 47%, rgba(0,0,0,0) 67%);

}

.gradient7(@_element : background-image) {

}



//Buttons
.button1{
	&, & a{
		display: inline-block;
		position: relative;
		z-index: 3;
		color: @c_4th;
		background-color:@c_6th;
		font-size: @f_mainSize;
		padding: @m_main (@m_main * 2);
		border:1px solid @c_4th;
		border-radius:@r_borderRadius;
		line-height: 100%;
		cursor:pointer;

		.f_1stFontSemiBold;
        
		.standardAnimation(all);

		&:hover{

			color:@c_5th;
			border:1px solid @c_5th;

			.shadow3;
		}
	}

	&.big{
		&, & a{
			font-size: 20px;
			padding: (@m_main / 1.4) @m_main;
		}
	}
}

.button-nav-1{
	width:35px;
	height:35px;
	display:block;
	border:1px solid @c_5th;
	border-radius:50%;
	background-color:@c_6th;
}

.button2{
	&, & a{
		display: inline-block;
		position: relative;
		z-index: 3;
		color: @c_5th;
		.f_1stFontSemiBold;
		font-size: 14px;
		text-transform: uppercase;
		padding: (@m_main / 2) @m_main;
		line-height: 100%;

		.icon{
			font-size: 16px;
			.fa;
			.fa-arrow-circle-o-right;
			margin-left: @m_main / 3;


			&:before{
				.transform2d(@translateX: 0);
				.standardAnimation();
			}
		}

		.standardAnimation(all);

		&:before, &:after{
			content: " ";
			position: absolute;
			left: 50%;
			top: 50%;
			width: 100%;
			height: 100%;
			.transform2d(@skewX: -11deg, @skewY: 0deg, @translateX: -50%, @translateY: -50%);
			-ms-transform-origin: 0% 0%;
			-webkit-transform-origin: 0% 0%;
			transform-origin: 0% 0%;

			.standardAnimation();
		}

		&:before{
			z-index: -2;
			opacity: 1.0;
			.gradient1;
			.shadow2;

		}

		&:after{
			opacity: 0.0;
			z-index: -1;
			.gradient2;
		}

		&:hover{

			&, .icon{
				color: @c_5th;
			}

			.icon{

				&:before{
					.transform2d(@translateX: 3px);
					.standardAnimation();
				}

			}


			&:after{
				opacity: 1.0;
				.standardAnimation(opacity);
			}

			&:before{
				.standardAnimation(opacity);
			}

		}
	}

	&.big{
		&, & a{
			font-size: 20px;
			padding: (@m_main / 1.4) @m_main;
		}
	}
}


.button3{
	&, & a{
		display: inline-block;
		position: relative;
		z-index: 3;
		color: @c_1st;
		.f_1stFontLight;
		font-size: 15px;
		padding: 0 (@m_main / 2);
		padding-left: (@m_main * 1.4);
		line-height: 120%;

		.standardAnimation(all);

		&:before, &:after{
			content: " ";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			.standardAnimation(opacity);
		}

		&:before{
			opacity: 1.0;
			z-index: -2;
		}

		&:after{
			opacity: 0.0;
			z-index: -1;
		}

		.icon{
			.fa;
			.fa-angle-right;

			&:before{
				position: absolute;
				top: 0;
				left: 0;
				margin-left: 0;
				.standardAnimation(all);
				width: 1em;
				height: 1em;
				font-size: 1em;
			}
		}

		&:hover{
			color: @c_4th;

			&:after{
				opacity: 1.0;
				.standardAnimation(opacity);
			}

			&:before{
				opacity: 0.0;
				.standardAnimation(opacity);
			}

			.icon{
				&:before{
					.standardAnimation(all);
					margin-left: 2px;
					.standardAnimation;
				}
			}
		}
	}

	&.big{
		&, & a{
			font-size: 19px;
		}
	}
}

// Shadows
.shadow1{
	-webkit-box-shadow: 0px 0px 25px 25px rgba(0, 0, 0, 0.08);
	-moz-box-shadow:    0px 0px 25px 25px rgba(0, 0, 0, 0.08);
	box-shadow:         0px 0px 25px 25px rgba(0, 0, 0, 0.08);
}

.shadow2{
	-webkit-box-shadow: 0 3px 2px rgba(21, 24, 46, 0.2);
	-moz-box-shadow: 0 3px 2px rgba(21, 24, 46, 0.2);
	box-shadow: 0 3px 2px rgba(21, 24, 46, 0.2);
}

.shadow3{
	-webkit-box-shadow: 0px 0px 75px 0px rgba( 112, 80, 28, 0.3 );
	-moz-box-shadow:    0px 0px 75px 0px rgba( 112, 80, 28, 0.3 );
	box-shadow:         0px 0px 75px 0px rgba( 112, 80, 28, 0.3 );
}

.shadow4{
    -webkit-box-shadow: 0px 0px 175px 0px rgba( 112, 80, 28, 0.1 );
    -moz-box-shadow:    0px 0px 175px 0px rgba( 112, 80, 28, 0.1 );
    box-shadow:         0px 0px 175px 0px rgba( 112, 80, 28, 0.1 );
}

.text-outline{
	-webkit-text-shadown: -2px -2px 0 @c_3rd, 2px -2px 0 @c_3rd, -2px 2px 0 @c_3rd, 2px 2px 0 @c_3rd;
	-moz-text-shadown: -2px -2px 0 @c_3rd, 2px -2px 0 @c_3rd, -2px 2px 0 @c_3rd, 2px 2px 0 @c_3rd;
	text-shadow: -2px -2px 0 @c_3rd, 2px -2px 0 @c_3rd, -2px 2px 0 @c_3rd, 2px 2px 0 @c_3rd;

	@media screen and ( max-width: @q_1st ){
		-webkit-text-shadown: -1px -1px 0 @c_3rd, 1px -1px 0 @c_3rd, -1px 1px 0 @c_3rd, 1px 1px 0 @c_3rd;
		-moz-text-shadown: -1px -1px 0 @c_3rd, 1px -1px 0 @c_3rd, -1px 1px 0 @c_3rd, 1px 1px 0 @c_3rd;
		text-shadow: -1px -1px 0 @c_3rd, 1px -1px 0 @c_3rd, -1px 1px 0 @c_3rd, 1px 1px 0 @c_3rd;
	}
}

// Slick Slideshow
.slickSlideshow{
	.slick{
		 .block-content{
			 position: relative;
			 .displayFlex(row);
			 .justifyContent(center);

			 .views-field-edit-node-1 a{
				 position: absolute;
				 z-index: 2500;
				 top: -20px;
				 left: 20px;
				 color: @c_1st;
				 .strong;
				 background: rgba(255,255,255,0.9);
				 padding: 10px;
				 opacity: 0.0;
				 .standardAnimation(all);
				 font-size: 14px;
			 }

			 .field-name-field-header-link a{
				 position: absolute;
				 top: 0;
				 left: 0;
				 width: 100%;
				 height: 100%;
				 display: block;
				 background: @c_1st;
				 opacity: 0.0;

			 }

			 .content{
				 position: absolute;
				 width: 100%;
				 height: 100%;
				 top: 0;
				 left: 50%;
				 .transform2d(@translateX: -50%);
				 z-index: 2;
				 .displayFlex(row);
				 .justifyContent(space-between);

				 min-width: @w_wrapper;
				 max-width: @w_wrapper;

				 h1, h2, p{

				 }
			 }

			 &:hover{
				 .views-field-edit-node-1 a{
					 top: 20px;
					 opacity: 1.0;
					 .standardAnimation(all);
				 }
			 }

			 .backgroundImage{
				 width: @w_header;
				 left: 0;
				 position: relative;
				 z-index: 1;

				 img{
					 min-width: @w_header;
					 width: 100% !important;
					 height: auto !important;
				 }
			 }
		 }


		 &.slick-active{

		 }

		 &slick-current{

		 }
	 }


	.slick__arrow{

		width: 100% !important;
		top: 50%;
		left: 50%;
		.transform2d(@translateX: -50%);
	//	.transform2d(@translateY: -50%);
		position: absolute;

		a.slick-arrow{
			@_width:  126px;
			@_height:  126px;
			width: @_width;
			height: @_height;

			@media all and (max-width: 1500px) {
				& {
					@_width:  80px;
					@_height:  80px;
					width: @_width;
					height: @_height;
				}
			}

			@media all and (max-width: 1260px) {
				& {
					@_width:  60px;
					@_height:  60px;
					width: @_width;
					height: @_height;
				}
			}



			border: 2px solid @c_5th;

			overflow: hidden;

			opacity: 0.9;
			display: block;
			cursor: pointer;

			.standardAnimation();

			.fa;

			position: absolute;
			top: 50%;
			.transform2d(@translateY: -50%);


			&:before{
				text-align: center;
				color: @c_5th;
				font-size: 50px;
				width: 1em;
				height: 1em;
				line-height: 1em;
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 50%;
				.transform2d(@translateX: -50%; @translateY: -50%);
				z-index: 2;


				.standardAnimation(all);
			}

			&:hover{
				opacity: 1.0;
				.standardAnimation(all);

				&:before{
					.standardAnimation(all);
				}
			}


			&.slick-prev{
				left: 14%;
				background-position: bottom left;

				.fa-angle-left;

				&:before{
					margin-left: 0;
				}

				&:hover{
					&:before{
						margin-left: -4px;
					}
				}

				@media all and (max-width: 1850px) {
					& {
						left: 2%;
					}
				}
			}

			&.slick-next{
				right: 14%;
				background-position: top left;

				.fa-angle-right;

				&:before{
					margin-right: 0;
				}

				&:hover{
					&:before{
						margin-left: 4px;
					}
				}

				@media all and (max-width: 1850px) {
					& {
						right: 2%;
					}
				}
			}
		}
	}


	&:hover{
		.slick__arrow{
			a.slick-arrow{
				.standardAnimation();
			}
		}
	}
}

//Slick Carousel
.slickCarousel{
	position: relative;
	z-index: 4;

	.slick{

		.slide__content{
			z-index: 2;
			position: relative;

			.content{
				padding: @m_main (@p_main * 3);
				position: relative;

				.views-field-edit-node{
					position: absolute;
					bottom: @m_main / 2;
					right: @m_main / 2;
					margin-bottom: -60px;
					margin-right: -60px;

					a{

						.standardAnimation();
						.button1;
						opacity: 0.0;
					}

				}

				&:hover{
					.views-field-edit-node{
						margin-bottom: 0;
						margin-right: 0;

						a{
							opacity: 1.0;
						}

					}
				}

			}
		}


		&.slick-active{

		}

		&slick-current{

		}
	}



	.slick__arrow{

		width: 100% !important;
		top: 50%;
		left: 50%;
		.transform2d(@translateX: -50%);
		//	.transform2d(@translateY: -50%);
		position: absolute;

		a.slick-arrow{
			@_width:  40px;
			@_height:  40px;
			width: @_width;
			height: @_height;
			color: fade(@c_1st, 50%);

			overflow: hidden;

			opacity: 0.9;
			display: block;
			cursor: pointer;

			.standardAnimation();

			.fa;

			position: absolute;
			top: 50%;
			.transform2d(@translateY: -50%);


			&:before{
				text-align: center;
				color: fade(@c_1st, 50%) !important;

				font-size: 60px;
				width: 1em;
				height: 1em;
				line-height: 1em;
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 50%;
				.transform2d(@translateX: -50%; @translateY: -50%);
				z-index: 2;


				.standardAnimation(all);
			}

			&:hover{
				opacity: 1.0;
				.standardAnimation(all);

				&:before{
					.standardAnimation(all);
				}
			}


			&.slick-prev{
				left: 0;
				background-position: bottom left;

				.fa-angle-left;

				&:before{
					margin-left: 0;
				}

				&:hover{
					&:before{
						margin-left: -4px;
					}
				}
			}

			&.slick-next{
				right: 0;
				background-position: top left;

				.fa-angle-right;

				&:before{
					margin-right: 0;
				}

				&:hover{
					&:before{
						margin-left: 4px;
					}
				}
			}
		}
	}

	.slick-dots{

		li{
			font-size: 0 !important;
			display: inline-block;
			margin: @m_main / 6 !important;
			padding: 0 !important;
			.standardAnimation();
			cursor: pointer;
			position: relative;
			z-index: 3;
			width: 10px !important;
			height: 10px !important;

			&:before, &:after{
				content: " ";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				.standardAnimation();
			}

			&:before{
				z-index: -1;
				opacity: 0.0;
				.gradient1;
			}

			&:after{
				z-index: -2;
				.gradient3;
			}


			&.slick-active{
				.standardAnimation();
				&:before{
					opacity: 1.0;
				}
			}
		}
	}


	&:hover{
		.slick__arrow{

		}
	}
}

// Galerie
.imageGallery{
	margin-top: @m_main;

	.views-row{
		width: 100%;
	}

	.field-slideshow-wrapper, .imageGallery{
		
		.field-slideshow{
			.field-slideshow-slide{
				a{
					display: block;

					img{
						width: 100% !important;
						height: auto !important;
						position: relative;
						z-index: 1;
					}

					position: relative;
					z-index: 4;


					.fa;
					.fa-plus;


					&:before{
						color: @c_5th;
						position: absolute;
						top: 50%;
						left: 50%;
						z-index: 5;
						height:60px;
						width: 60px;
						line-height: 60px;
						font-size: 40px;
						opacity: 0.0;
						.standardAnimation;
						.transform2d(@translateX: -50%, @translateY: -50%)
					}

					&:after{
						content: " ";
						display: block;
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						.gradient2;
						z-index: 3;

						.standardAnimation;
						opacity: 0.0;
					}

					&:hover{
						&:before{
							font-size: 60px;
							opacity: 1.0;
							.standardAnimation;
						}

						&:after{
							.standardAnimation;
							opacity: 0.4;
						}
					}
				}
				
				.field-slideshow-caption{
					.field-slideshow-caption-text{
						.text;
					}
				}
			}
		}
		
		.item-list, .field-type-image{
			.field-slideshow-pager, .field-items{
				.displayFlex(row);
				.justifyContent(flex-start);
				.alignItems(center);
				.alignContent(flex-start);
				.flexWrap(wrap);
				.gradient1;
				padding: (@m_main / 2);
				
				@_itemElements: 4;
				
				width: 100%;

				li, .field-item{
					.pReset;
					float: none;
					display: inline-block;
					padding: (@m_main / 2) (@m_main / 4);
					

					width: 100% / @_itemElements;
					overflow: hidden;

					position: relative;

					a{
						.pReset;
						position: relative;
						display: block;
						height: 100%;
						width: 100%;
						overflow: hidden;

						&:hover{
							&:before{
								font-size: 30px;
								opacity: 1.0;
								.standardAnimation;
							}

							&:after{
								.standardAnimation;
								opacity: 0.4;
							}
						}



						.fa;
						.fa-plus;


						&:before{
							color: @c_5th;
							position: absolute;
							margin-top: -4px;
							margin-left: -15px;
							width: 30px;
							height: 30px;
							line-height: 0;
							top: 50%;
							left: 50%;
							z-index: 5;
							font-size: 1px;
							opacity: 0.0;
							.standardAnimation;
						}
						
						&:after{
							content: " ";
							display: block;
							position: absolute;
							left: 0;
							top: 0;
							width: 100%;
							height: 100%;
							.gradient2;
							z-index: 3;
							
							.standardAnimation;
							opacity: 0.0;
						}
						
						img{
							position: relative !important;
							z-index: 1 !important;
						}
					}
					
					&.activeSlide{
						a{
							&:before{
								opacity: 1.0;
							}
						}
					}
					

				}
			}
		}
	}
}

// Keine Silbentrennung
.donthyphenate{
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	-o-hyphens: none;
	hyphens: none;
}


//Inline Video
.inlineVideo{
	width: 100%;
	overflow: hidden;



	video{
		position: relative;
		cursor: pointer;
		z-index: 1;
	}
}

// Spezifische  Klassen
.labelButton{
	.gradient2;
	color: @c_5th;
	.f_1stFontBold;
	text-transform: uppercase;
	font-size: 11px;
	line-height: 100%;
	padding: (@m_main / 4) (@m_main / 2);
	border-right: 2px solid @c_6th;
	border-bottom: 3px solid @c_6th;
	display: inline-block;
}

.labelButtonDark{
	.gradient5;
	color: @c_5th;
	.f_1stFontBold;
	text-transform: uppercase;
	font-size: 11px;
	line-height: 100%;
	padding: (@m_main / 4) (@m_main / 2);
	border-right: 2px solid #4b4b4b;
	border-bottom: 3px solid #4b4b4b;
	display: inline-block;
}

//FullBackground Block
.fullBackgroundBlock{

	.block-content{
		overflow: hidden;
		position: relative;

		.block-title{
			.pReset;

			a{
				position: absolute;
				z-index: 3;
				left: @m_main * 2;
				bottom: @m_main * 3.6;
				.labelButton;
			}

		}

		.content{

			&:after{
				content: " ";
				display: block;
				background: @c_2nd;
				z-index: 2;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0.0;
				.standardAnimation();
				mix-blend-mode: color;
				-webkit-box-shadow: inset 0px 0px 119px 25px rgba(0,0,0,1);
				-moz-box-shadow: inset 0px 0px 119px 25px rgba(0,0,0,1);
				box-shadow: inset 0px 0px 119px 25px rgba(0,0,0,1);
			}

			& > p{
				.pReset;

				img{
					width: 100% !important;
					height: auto !important;
					.standardAnimation();
					.transform2d(@scale: 1.0);
					position: relative;
					z-index: 1;
				}
			}

			h1, h2{
				position: absolute;
				z-index: 3;
				left: @m_main * 2;
				bottom: 0;
				color: @c_5th;
				text-shadow: 2px 1px 1px rgba(0, 0, 0, 0.95);
				font-size: 30px;

				@media screen and (max-width: 1275px) {
					&{
						font-size: 26px;
					}
				}

				@media screen and (max-width: 1135px) {
					&{
						font-size: 22px;
					}
				}

			}

		}

		.block-link{
			a{
				position: absolute;
				width: 100%;
				height: 100%;
				display: block;
				top: 0;
				left: 0;
				z-index: 4;
				opacity: 0.0;
				padding: 0;

				&:after, &:before{
					display: none;
				}
			}
		}


		&:hover{
			.content{
				& > p {
					img{
						.standardAnimation(@_time: 0.6s);
						.transform2d(@scale: 1.2);
					}
				}

				&:after{
					opacity: 0.4;
					.standardAnimation(@_time: 0.8s);
				}
			}
		}
	}
}

.fadeOut{
	opacity:0;
}
.fadeIn{
	.animation-fadeIn;
}

.active{
	display:block;
}

.inactive{
	display:none;
}

.visible{
	display:block;
}

.invisible{
	display:none;
}

//Silbentrennung
.hyphenate{
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	-o-hyphens: auto;
	hyphens: auto;
}

.block-link{
	a{
		.button1;
	}
}

// VideoPlayer Klassen

.track() {
	width: @track-width;
	height: @track-height;
	cursor: pointer;
	animate: 0.2s;
}

.thumb() {
	//.shadow(@thumb-shadow-size,@thumb-shadow-blur,@thumb-shadow-color);
	border: 2px solid @c_5th;
	height: @thumb-height;
	width: @thumb-width;
	border-radius: @thumb-radius;
	background: @c_6th;
	cursor: pointer;
}

.blur{
	filter: blur(8px);
  -webkit-filter: blur(8px);
}