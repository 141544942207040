#nav-cci{

    display:block;
    width:100%;
    height:auto;
    border-bottom: 1px solid @c_7th;
    background-color:@c_3rd;
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index:100000;

    .wrapper-nav-cci{
        
        display:inline-block;
        height:100%;
        width:100%;

        .displayFlex(row);
        .flexWrap(nowrap);
        .alignContent(flex-start);
        .justifyContent(space-between);

        #location-name{

            display:inline-block;
            height:30%;
    
            h1{
                margin:0;
                margin-top:(@p_main / 2);
                margin-left:(@p_main / 2);
                padding:0;
                color:@c_6th;
    
                .f_1stFont;
            }
        }
        
        ul{
            list-style:none;
            margin:0;
            padding: (@p_main / 2) 0 (@p_main / 2) 0;

            .displayFlex(row);
            .flexWrap(nowrap);
            .alignContent(flex-start);
            .justifyContent(flex-end);

            li{
                display:inline-block;
                margin-right:(@p_main / 2);

                a.button1{
                    border-radius:0;
                    background-color:@c_3rd;
                    color:@c_6th;
                    text-decoration:none;
                    padding: @p_main ;
                    border:none;

                }
                &:last-child{
                    margin-right:@p_main;
                }
            }
        }
    }
}